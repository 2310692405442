/* inter-200 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 200;
  src: url("./inter-v11-latin-200.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./inter-v11-latin-200.woff") format("woff"),
    /* Modern Browsers */ url("./inter-v11-latin-200.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* inter-300 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 300;
  src: url("./inter-v11-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./inter-v11-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("./inter-v11-latin-300.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* inter-600 - latin */
@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  src: url("./inter-v11-latin-600.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./inter-v11-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("./inter-v11-latin-600.ttf") format("truetype"); /* Safari, Android, iOS */
}

/* ibm-plex-mono-300 - latin */
@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: 300;
  src: url("./ibm-plex-mono-v12-latin-300.woff2") format("woff2"),
    /* Super Modern Browsers */ url("./ibm-plex-mono-v12-latin-300.woff")
      format("woff"),
    /* Modern Browsers */ url("./ibm-plex-mono-v12-latin-300.ttf")
      format("truetype"); /* Safari, Android, iOS */
}
