:root {
  font-size: 62.5%;
}
html {
  scrollbar-color: #545454 #171717;
  scroll-behavior: smooth;
  cursor: url("data:image/svg+xml,%3Csvg width='20' height='21' viewBox='0 0 13 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.62016 1.44998L11.0936 6.34021C12.316 7.14008 11.7902 9.03764 10.3304 9.09425L8.96203 9.14732C7.98237 9.18531 7.11534 9.79241 6.74458 10.7L6.22669 11.9677C5.67423 13.3201 3.71126 13.1652 3.37771 11.7429L1.33847 3.04763C1.0293 1.72934 2.48711 0.708571 3.62016 1.44998Z' fill='%231B1B1B' stroke='%23E2E2E2'/%3E%3C/svg%3E%0A")
      0 0,
    auto;
}
::-webkit-scrollbar {
  background-color: #171717;
}
::-webkit-scrollbar-thumb {
  background-color: #545454;
}
#bg {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  object-fit: cover;
  object-position: 30% 50%;
  z-index: -1;
  pointer-events: none;
}
model-viewer {
  --progress-bar-color: transparent;
}
h2 {
  scroll-margin-top: 150px;
  scroll-snap-margin-top: 150px;
}

h2#resume {
  scroll-margin-top: 300px;
  scroll-snap-margin-top: 300px;
}

section {
  padding: 50px 0;
  position: relative;
}

section:first-of-type {
  padding: 0px 0 100px;
}

section:nth-of-type(2) {
  padding-top: 0;
}

@media (min-width: 640px) {
  h2 {
    scroll-margin-top: 100px;
    scroll-snap-margin-top: 100px;
  }
  section {
    padding: 100px 0;
  }
  section:first-of-type {
    padding: 50px 0 30px;
  }
}
