#puzzleSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container {
  width: 95%;
  max-width: 400px;
}
.puzzle {
  position: relative;
  border-radius: 10px;
  background-color: transparent;
  overflow: hidden;
  padding-bottom: 100%;
  transition: transform 0.25s ease;
  will-change: transform;
}

.square {
  border: 5px solid transparent;
  background-color: transparent;
  position: absolute;
  width: 25%;
  padding: 0;
  transition: 0.25s ease;
  cursor: pointer;
  will-change: transform;
}

.square .content {
  border-radius: 6px;
  padding-bottom: 100%;
  background: linear-gradient(45deg, #131313, #2b2b2b);
  box-shadow: inset -1px 1px 0 1px rgba(255, 255, 255, 0.05);
}

.empty {
  opacity: 0;
}

.square .content .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
  font-family: "IBM Plex Mono", Menlo, monospace;
  transition: opacity 0.3s ease 0.5s;
  color: #e2e2e2;
}

.puzzle.complete {
  transform: scale(0.9);
  transition: transform 0.25s ease 3s;
  pointer-events: none;
}

.puzzle::after {
  content: "";
  background: url(./qrcode.svg), linear-gradient(45deg, #131313, #2b2b2b);
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  pointer-events: none;
  opacity: 0;
  transition: opacity 0.25s ease;
}

.puzzle.complete::after {
  opacity: 1;
  transition: opacity 0.25s ease 3s;
}

.puzzle.complete .square {
  transform: scale(1.2);
  transition: 0.25s ease, transform 0.25s ease 3s;
}

.puzzle.complete .empty {
  opacity: 1;
  transition: 0.25s ease, transform 0.25s ease 3s, opacity 0.35s ease 2s;
}

.puzzle.complete .number {
  opacity: 0;
  transition: opacity 0.3s ease 1s;
}

#shuffle {
  padding: 0;
  cursor: pointer;
  width: 46px;
  height: 46px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
}

#shuffle > div {
  display: flex;
}

@media (max-width: 375px) {
  .square .content .number {
    font-size: 25px;
  }
}
